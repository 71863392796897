<template>
    <div>
        <!-- 统计报表 -->
        <touTop :headername="headerName" />
        <div class="reportForm">
            <div class="left">
                <sider 
                :headername="headerName" 
                :sideMenus="sideMenu"
                @transkey="getKey"
                />
            </div>
            <div class="right">
                <router-view/>
            </div>
        </div>
    </div>
</template>
<script>
import touTop from "@/components/touTop/touTop";
import sider from "@/components/sider/sider";
export default {
    name:'reportForm',
    data(){
        return{
            headerName: "统计报表",
            sideMenu: [
                { key: 0, value: "业绩分析" },
                { key: 1, value: "订单统计" },
                
            ],
        }
    },
    methods:{
        getKey(key){
            if(key===0){
                this.$router.push({path: '/reportForm/statistics',query: {key: key}})
            };
            if(key===1){
                this.$router.push({path: '/reportForm/orderCount',query: {key: key}})
            };
        }
    },
    components: {
        touTop,
        sider,
    },
}
</script>
<style lang="less" scoped>
.reportForm {
    width: 100%;
    display: flex;
    height: 95.4vh;
    .left {
        width: 17vw;
        background: rgba(46, 62, 78, 1);
        display: flex;
    }
    .right{
        flex:1;
    }
}
</style>